import axios from 'axios'
const state = {
    loading: false,
    loadingCom: false,
    cars: [],
    car: null,
    line: null,
    lines: null,
    maintenRec: {
        tableHeaders: [],
        tableData: [],
        accessTableData: [],
        loading: false,
        tableMeta: {
            total: 1000,
            page: 1,
            perPage: 1000,
        },
    },
    tableOptions: {
        tableHeaders: [],
        tableData: [],
        accessTableData: [],
        loading: false,
        tableMeta: {
            total: 10,
            page: 1,
        },
    },
    tableOptionsLine: {
        tableHeaders: [],
        tableData: [],
        accessTableData: [],
        loading: false,
        tableMeta: {
            total: 10,
            page: 1,
        },
    }
};
const getters = {
    getLoadingStatus(state) {
        return state.loading;
    },
    getLoadingComStatus(state) {
        return state.loadingCom;
    },
    getCar(state) {
        return state.car
    },
    getCars(state) {
        return state.cars
    },
    getLines(state) {
        return state.lines
    },
    getLine(state) {
        return state.line
    },
    getTableOptions(state) {
        return state.tableOptions
    },
    getTableOptionsLine(state) {
        return state.tableOptionsLine
    },
    getMaintenRec(state) {
        return state.maintenRec
    },
};

const mutations = {
    setLoadingStatus(state, status) {
        state.loading = status;
    },
    setLoadingComStatus(state, status) {
        state.loadingCom = status;
    },
    setCar(state, car) {
        state.car = car;
    },
    setCars(state, cars) {
        state.cars = cars
    },
    setLines(state, lines) {
        state.lines = lines
    },
    setLine(state, line) {
        state.line = line
    },
    setLoading(state, status) {
        state.tableOptions.loading = status
    },
    setTableData(state, tableInfo) {
        state.tableOptions.tableHeaders = tableInfo.tableHeaders
        state.tableOptions.tableData = tableInfo.tableData
        state.tableOptions.accessTableData = tableInfo.accessTableData
        state.tableOptions.tableMeta.total = tableInfo.tableMeta.total
        state.tableOptions.tableMeta.page = tableInfo.tableMeta.page
    },
    setTableDataLine(state, tableInfo) {
        state.tableOptionsLine.tableHeaders = tableInfo.tableHeaders
        state.tableOptionsLine.tableData = tableInfo.tableData
        state.tableOptionsLine.accessTableData = tableInfo.accessTableData
        state.tableOptionsLine.tableMeta.total = tableInfo.tableMeta.total
        state.tableOptionsLine.tableMeta.page = tableInfo.tableMeta.page
    },
    setMaintenRec(state, tableInfo) {
        state.maintenRec.tableHeaders = tableInfo.tableHeaders
        state.maintenRec.tableData = tableInfo.tableData
        state.maintenRec.accessTableData = tableInfo.accessTableData
        // state.maintenRec.tableMeta.total = tableInfo.tableMeta.total
        // state.maintenRec.tableMeta.page = tableInfo.tableMeta.page
    },
};

const actions = {
    async fetchCars({ commit }, queryParams) {
        try {
            commit('setLoading', true)
            let params = {};
            for (let key in queryParams) {
                if (queryParams[key]) {
                    params[key] = queryParams[key];
                }
            }
            if(!queryParams.paginated) params.paginated = queryParams.paginated;
            const response = await axios.get("/b2b/car", {
                params,
            });
            commit('setCars', response.data.data)
            commit('setTableData', {
                tableHeaders: [
                    "name",
                    "Responsible",
                    "Plate Number",
                    "Mechanic Number",
                    "Note",
                    "settings",
                ],
                tableData: response.data.data,
                accessTableData: [
                    ["name"],
                    ["responsible"],
                    ["plate_number"],
                    ["mechanic_number"],
                    ["notes"],
                ],
                tableMeta: {
                    total: response.data.meta.total,
                    page: response.data.meta.current_page,
                },
            })
        } catch (err) {
            console.log('err', err);
        } finally {
            commit('setLoading', false)
        }
    },
    async fetchCar({ commit }, id) {
        try {
            commit('setLoadingStatus', true)
            const response = await axios.get(`/b2b/car/${id}`);
            commit('setCar', response.data.data)
        } catch (err) {
            console.log('err', err);
        } finally {
            commit('setLoadingStatus', false)
        }
    },
    async fetchLines({ commit }, queryParams) {
        try {
            commit('setLoading', true)
            let params = {};
            for (let key in queryParams) {
                if (queryParams[key]) {
                    params[key] = queryParams[key];
                }
            }
            if(!queryParams.paginated) params.paginated = queryParams.paginated;
            const response = await axios.get("/b2b/line", {
                params,
            });
            commit('setLines', response.data.data)
            commit('setTableDataLine', {
                tableHeaders: [
                    "id",
                    "name",
                    "settings",
                ],
                tableData: response.data.data,
                accessTableData: [
                    ["id"],
                    ["name"],
                    ],
                tableMeta: {
                    total: response.data.meta.total,
                    page: response.data.meta.current_page,
                },
            })
        } catch (err) {
            console.log('err', err);
        } finally {
            commit('setLoading', false)
        }
    },
    async fetchLine({ commit }, id) {
        try {
            commit('setLoadingStatus', true)
            const response = await axios.get(`/b2b/line/${id}`);
            commit('setLine', response.data.data)
        } catch (err) {
            console.log('err', err);
        } finally {
            commit('setLoadingStatus', false)
        }
    },
    async fetchMaintenRec({ commit }, queryParams) {
        try {
            commit('setLoadingComStatus', true)
            let params = {};
            for (let key in queryParams) {
                if (queryParams[key]) {
                    params[key] = queryParams[key];
                }
            }
            if(!queryParams.paginated) params.paginated = queryParams.paginated;
            const response = await axios.get(`/b2b/maintenance-record`,{params});
            commit('setMaintenRec', {
                tableHeaders: [
                    "id",
                    "date",
                    "cost",
                    "Responsible",
                    "description",
                    "settings",
                ],
                tableData: response.data.data,
                accessTableData: [
                    ["id"],
                    ["date"],
                    ["cost"],
                    ["responsible"],
                    ["description"],
                ],
                // tableMeta: {
                //     total: response.data.meta?.total ?? 1000,
                //     page: response.data.meta?.current_page ?? 1,
                // },
            })
        } catch (err) {
            console.log('err', err);
        } finally {
            commit('setLoadingComStatus', false)
        }
    },
    
}


export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}