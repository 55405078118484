import axios from 'axios'
const state = {
    loading: false,
    loadingCom: false,
    customers: [],
    communication: [],
    payment: null,
    notes: {
        tableHeaders: [],
        tableData: [],
        accessTableData: [],
        loading: false,
        tableMeta: {
            total: 10,
            page: 1,
        },
    },
    customer: {
        data: {},
        id: null,
        name: null,
        image: null,
        account: null,
        communications: null,
        information: {
          code: {
            desc: "Barcode",
            value: "",
          },
          tax_number: {
            desc:"Tax Number",
            value:""
          },
          type: {
            desc:"Type",
            value:""
          },
          phone: {
            desc: "hr.employee.phone",
            value: "",
          },
          email: {
            desc: "hr.employee.email",
            value: "",
          },
          classification: {
            desc: "Classification",
            value: "",
          },
          phone2: {
            desc: "hr.employee.phone2",
            value: "",
          },
          address: {
            desc: "address",
            value: "",
          },
          group: {
            desc: "Group",
            value: "",
          },
          coin: {
            desc: "Currency",
            value: "",
          },
        }
    },
    tableOptions: {
        tableHeaders: [],
        tableData: [],
        accessTableData: [],
        loading: false,
        tableMeta: {
            total: 10,
            page: 1,
        },
    }
};
const getters = {
    getLoadingStatus(state) {
        return state.loading;
    },
    getLoadingCom(state) {
        return state.loadingCom;
    },
    getCustomer(state) {
        return state.customer
    },
    getCustomers(state) {
        return state.customers
    },
    getTableOptions(state) {
        return state.tableOptions
    },
    getCommunication(state) {
        return state.communication
    },
    getPayment(state) {
        return state.payment
    },
    getNotes(state) {
        return state.notes
    },
};

const mutations = {
    setLoadingStatus(state, status) {
        state.loading = status;
    },
    setLoadingCom(state, status) {
        state.loadingCom = status;
    },
    setCommunication(state, communication) {
        state.communication = communication;
    },
    setCustomer(state, customer) {
        state.customer.data = customer;
        state.customer.id = customer.id;
        state.customer.name = customer.name;
        state.customer.image = customer.image;
        state.customer.communications = customer.communications;
        state.customer.account = customer.account;
        state.customer.information.code.value = customer.code;
        state.customer.information.tax_number.value = customer.tax_number;
        state.customer.information.type.value = customer.type;
        state.customer.information.phone.value = customer.phone;
        state.customer.information.email.value = customer.email;
        state.customer.information.classification.value = customer.classification;
        state.customer.information.phone2.value = customer.phone2;
        state.customer.information.address.value = customer.address;
        state.customer.information.group.value = customer.group.name;
        state.customer.information.coin.value = customer.coin.name;
    },
    setCustomers(state, customers) {
        state.customers = customers
    },
    setLoading(state, status) {
        state.tableOptions.loading = status
        state.notes.loading = status
    },
    setTableData(state, tableInfo) {
        state.tableOptions.tableHeaders = tableInfo.tableHeaders
        state.tableOptions.tableData = tableInfo.tableData
        state.tableOptions.accessTableData = tableInfo.accessTableData
        state.tableOptions.tableMeta.total = tableInfo.tableMeta.total
        state.tableOptions.tableMeta.page = tableInfo.tableMeta.page
    },
    setNotes(state, tableInfo) {
        state.notes.tableHeaders = tableInfo.tableHeaders
        state.notes.tableData = tableInfo.tableData
        state.notes.accessTableData = tableInfo.accessTableData
        state.notes.tableMeta.total = tableInfo.tableMeta.total
        state.notes.tableMeta.page = tableInfo.tableMeta.page
    },
};

const actions = {
    async fetchCustomers({ commit }, queryParams) {
        try {
            commit('setLoading', true)
            let params = {};
            for (let key in queryParams) {
                if (queryParams[key]) {
                    params[key] = queryParams[key];
                }
            }
            if(!queryParams.paginated) params.paginated = queryParams.paginated;
            const response = await axios.get("/b2b/customer", {
                params,
            });
            let custo = response.data.data.map((el)=>{return{...el,isMyCusto: false}})
            commit('setCustomers', response.data.data)
            commit('setTableData', {
                tableHeaders: [
                    "name",
                    "hr.employee.phone",
                    "Zone",
                    "Group Name",
                    "Type",
                    "settings",
                ],
                tableData: custo,
                accessTableData: [
                    ["name"],
                    ["phone"],
                    ["zone","name"],
                    ["group","name"],
                    ["type"],
                ],
                tableMeta: {
                    total: response.data.meta.total,
                    page: response.data.meta.current_page,
                    perPage: queryParams.size ?? 10,
                },
            })
        } catch (err) {
            console.log('err', err);
        } finally {
            commit('setLoading', false)
        }
    },
   
    async fetchCustomer({ commit }, id) {
        try {
            commit('setLoadingStatus', true)
            const response = await axios.get(`/b2b/customer/${id}`);
            commit('setCustomer', response.data.data)
        } catch (err) {
            console.log('err', err);
        } finally {
            commit('setLoadingStatus', false)
        }
    },
    async fetchCommunication({ commit }, id) {
        try {
            commit('setLoadingCom', true)
            const response = await axios.get(`/b2b/customer/${id}/communication`);
            commit('setCommunication', response.data.data)
        } catch (err) {
            console.log('err', err);
        } finally {
            commit('setLoadingCom', false)
        }
    },
    async fetchPayments({ commit }, id) {
        try {
            commit('setLoadingStatus', true)
            const response = await axios.get(`/b2b/customer/${id}/payment`);
            state.payment = response.data.data;
        } catch (err) {
            console.log('err', err);
        } finally {
            commit('setLoadingStatus', false)
        }
    },
    async fetchNotes({ commit }, queryParams) {
        try {
            commit('setLoadingStatus', true)
            let params = {};
            for (let key in queryParams) {
                if (queryParams[key]) {
                    params[key] = queryParams[key];
                }
            }
            if(!queryParams.paginated) params.paginated = queryParams.paginated;
            const response = await axios.get(`/b2b/customer/${params.cuId}/note`,{
                params,
            });
            commit('setNotes', {
                tableHeaders: [
                    "date",
                    "title",
                    "Note",
                    "user name",
                    "settings",
                ],
                tableData: response.data.data,
                accessTableData: [
                    ["created_at"],
                    ["title"],
                    ["note"],
                    ["user"],
                ],
                tableMeta: {
                    total: 10, //response.data.meta.total,
                    page: 1, //response.data.meta.current_page,
                },
            })
        } catch (err) {
            console.log('err', err);
        } finally {
            commit('setLoadingStatus', false)
        }
    },
    postNewCustomer({ commit }, newCustomer) {
        return new Promise(async (resolve, reject) => {
            try {
                let form = new FormData();
                for (let key in newCustomer) {
                    if (newCustomer[key]) {
                        form.append(`${key}`, newCustomer[key])
                    }
                }
                newCustomer.id ? await axios.post(`/b2b/customer/${newCustomer.id}`, form) : await axios.post("/b2b/customer", form);
                resolve()
            }
            catch (err) {
                reject()
            }
        })
    },
}


export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}