import axios from 'axios'
const state = {
    loading: false,
    loadingCom: false,
    scheduleTrips: [],
    scheduleTrip: null,
    tableOptions: {
        tableHeaders: [],
        tableData: [],
        accessTableData: [],
        loading: false,
        tableMeta: {
            total: 10,
            page: 1,
        },
    },
};
const getters = {
    getLoadingStatus(state) {
        return state.loading;
    },
    getLoadingComStatus(state) {
        return state.loadingCom;
    },
    getScheduleTrip(state) {
        return state.scheduleTrip
    },
    getScheduleTrips(state) {
        return state.scheduleTrips
    },
    getTableOptions(state) {
        return state.tableOptions
    },
};

const mutations = {
    setLoadingStatus(state, status) {
        state.loading = status;
    },
    setLoadingComStatus(state, status) {
        state.loadingCom = status;
    },
    setScheduleTrip(state, scheduleTrip) {
        state.scheduleTrip = scheduleTrip;
    },
    setScheduleTrips(state, scheduleTrips) {
        state.scheduleTrips = scheduleTrips
    },
    setLoading(state, status) {
        state.tableOptions.loading = status
    },
    setTableData(state, tableInfo) {
        state.tableOptions.tableHeaders = tableInfo.tableHeaders
        state.tableOptions.tableData = tableInfo.tableData
        state.tableOptions.accessTableData = tableInfo.accessTableData
        state.tableOptions.tableMeta.total = tableInfo.tableMeta.total
        state.tableOptions.tableMeta.page = tableInfo.tableMeta.page
    },
};

const actions = {
    async fetchScheduleTrips({ commit }, queryParams) {
        try {
            commit('setLoading', true)
            let params = {};
            for (let key in queryParams) {
                if (queryParams[key]) {
                    params[key] = queryParams[key];
                }
            }
            if(!queryParams.paginated) params.paginated = queryParams.paginated;
            const response = await axios.get("/b2b/trip-schedule", {
                params,
            });
            commit('setScheduleTrips', response.data.data)
            commit('setTableData', {
                tableHeaders: [
                    "Representative",
                    "Car",
                    "Line Name",
                    "Day Name",
                    "Time",
                    "Note",
                    "settings",
                ],
                tableData: response.data.data,
                accessTableData: [
                    ["representative","name"],
                    ["car","name"],
                    ["line","name"],
                    ["day"],
                    ["time"],
                    ["notes"],
                ],
                tableMeta: {
                    total: response.data.meta.total,
                    page: response.data.meta.current_page,
                },
            })
        } catch (err) {
            console.log('err', err);
        } finally {
            commit('setLoading', false)
        }
    },
    async fetchScheduleTrip({ commit }, id) {
        try {
            commit('setLoadingStatus', true)
            const response = await axios.get(`/b2b/trip-schedule/${id}`);
            commit('setScheduleTrip', response.data.data)
        } catch (err) {
            console.log('err', err);
        } finally {
            commit('setLoadingStatus', false)
        }
    },
}


export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}