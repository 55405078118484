import axios from 'axios'
const state = {
    loading: false,
    representatives: [],
    representative: {
        id: null,
        user: null,
        account: null,
        information: {
          name: '',
          percentage: {
            desc: "Percent",
            value: "",
          },
          phone: {
            desc: "phone",
            value: "",
          },
          username: {
            desc:"user name",
            value:""
          },
          address: {
            desc: "address",
            value: "",
          },
          phone2: {
            desc: "phone2",
            value: "",
          },
          group: {
            desc: "Group",
            value: "",
          },
          notes: {
            desc: "note",
            value: "",
          },
        }
    },
    tableOptions: {
        tableHeaders: [],
        tableData: [],
        accessTableData: [],
        loading: false,
        tableMeta: {
            total: 10,
            page: 1,
        },
    }
};
const getters = {
    getLoadingStatus(state) {
        return state.loading;
    },
    getRepresentative(state) {
        return state.representative
    },
    getRepresentatives(state) {
        return state.representatives
    },
    getTableOptions(state) {
        return state.tableOptions
    },
};

const mutations = {
    setLoadingStatus(state, status) {
        state.loading = status;
    },
    setRepresentative(state, representative) {
        state.representative.id = representative.id;
        state.representative.user = representative.user;
        state.representative.account = representative.account;
        state.representative.information.name = representative.name;
        state.representative.information.percentage.value = representative.percentage;
        state.representative.information.phone.value = representative.phone;
        state.representative.information.username.value = representative.user?.username;;
        state.representative.information.address.value = representative.address;
        state.representative.information.phone2.value = representative.phone2;
        state.representative.information.group.value = representative.group?.name;
        state.representative.information.notes.value = representative.notes;
    },
    setRepresentatives(state, representatives) {
        state.representatives = representatives
    },
    setLoading(state, status) {
        state.tableOptions.loading = status
    },
    setTableData(state, tableInfo) {
        state.tableOptions.tableHeaders = tableInfo.tableHeaders
        state.tableOptions.tableData = tableInfo.tableData
        state.tableOptions.accessTableData = tableInfo.accessTableData
        state.tableOptions.tableMeta.total = tableInfo.tableMeta.total
        state.tableOptions.tableMeta.page = tableInfo.tableMeta.page
    },
};

const actions = {
    async fetchRepresentatives({ commit }, queryParams) {
        try {
            commit('setLoading', true)
            let params = {};
            for (let key in queryParams) {
                if (queryParams[key]) {
                    params[key] = queryParams[key];
                }
            }
            if(!queryParams.paginated) params.paginated = queryParams.paginated;
            const response = await axios.get("/b2b/representative", {
                params,
            });
            commit('setRepresentatives', response.data.data)
            commit('setTableData', {
                tableHeaders: [
                    "name",
                    "Percent",
                    "phone",
                    "address",
                    "Group Name",
                    "settings",
                ],
                tableData: response.data.data,
                accessTableData: [
                    ["name"],
                    ["percentage"],
                    ["phone"],
                    ["address"],
                    ["group","name"],
                ],
                tableMeta: {
                    total: response.data.meta.total,
                    page: response.data.meta.current_page,
                },
            })
        } catch (err) {
            console.log('err', err);
        } finally {
            commit('setLoading', false)
        }
    },
    async fetchRepresentative({ commit }, id) {
        try {
            commit('setLoadingStatus', true)
            const response = await axios.get(`/b2b/representative/${id}`);
            commit('setRepresentative', response.data.data)
        } catch (err) {
            console.log('err', err);
        } finally {
            commit('setLoadingStatus', false)
        }
    },
    
}


export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}