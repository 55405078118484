import axios from 'axios'
const state = {
    representativesgroups: [],
    tableOptions: {
        tableHeaders: [],
        tableData: [],
        accessTableData: [],
        loading: false,
        tableMeta: {
            total: 10,
            page: 1,
        },
    }
};
const getters = {
    getRepresentativesGroups(state) {
        return state.representativesgroups
    },
    getTableOptions(state) {
        return state.tableOptions
    },
};

const mutations = {
    setRepresentativesGroups(state, representativesgroups) {
        state.representativesgroups = representativesgroups
    },
    setLoading(state, status) {
        state.tableOptions.loading = status
    },
    setTableData(state, tableInfo) {
        state.tableOptions.tableHeaders = tableInfo.tableHeaders
        state.tableOptions.tableData = tableInfo.tableData
        state.tableOptions.accessTableData = tableInfo.accessTableData
        state.tableOptions.tableMeta.total = tableInfo.tableMeta.total
        state.tableOptions.tableMeta.page = tableInfo.tableMeta.page
    }
};

const actions = {
    async fetchRepresentativesGroups({ commit }, queryParams) {
        try {
            commit('setLoading', true)
            let params = {};
            for (let key in queryParams) {
                if (queryParams[key]) {
                    params[key] = queryParams[key];
                }
            }
            if(!queryParams.paginated) params.paginated = queryParams.paginated;
            const response = await axios.get("/b2b/representative-group", {
                params,
            });
            commit('setRepresentativesGroups', response.data.data)
            commit('setTableData', {
                tableHeaders: [
                    "name",
                    "term",
                    "Sales Invoice",
                    "Sales Refunds Invoice",
                    "settings",
                ],
                tableData: response.data.data,
                accessTableData: [
                    ["name"],
                    ["term","name"],
                    ["sales_invoice","name"],
                    ["sale_refunds_invoice","name"],
                ],
                tableMeta: {
                    total: response.data.meta.total,
                    page: response.data.meta.current_page,
                },
            })
        } catch (err) {
            console.log('err', err);
        } finally {
            commit('setLoading', false)
        }
    },
    
}


export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}